export default class GameplayErrorTypes {
  static HERO_RAN_IN_WALL = 'hero_ran_in_wall';
  static HERO_RAN_IN_ENEMY = 'hero_ran_in_enemy';
  static NO_ENEMIES_TO_ATTACK = 'no_enemies_to_attack';
  static NO_ENEMY_WITH_GIVEN_NAME = 'no_enemy_with_given_name';
  static ENEMY_TOO_FAR = 'enemy_too_far';
  static NO_LEVERS = 'no_levers';
  static NO_LEVER_WITH_GIVEN_NAME = 'no_lever_with_given_name';
  static LEVER_TOO_FAR = 'lever_too_far';
  static CANT_BE_HERE = 'cant_be_here';
  static ENEMY_SHOULD_NOT_BE_HERE = 'enemy_should_not_be_here';
  static HERO_KILLED_BY_ENEMY = 'HERO_KILLED_BY_ENEMY';
}
